import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import MyLink from "../../myLink"
import * as styles from "./style.module.scss"

const ContactBottomInfo = () => {
  const {
    sanityBookADemo: { bottomInfo: data },
  } = useStaticQuery(graphql`
    query DemoBottomInfoQuery {
      sanityBookADemo {
        bottomInfo {
          title
          Links {
            _key
            internallink
            linktext
            newwindow
            url
          }
        }
      }
    }
  `)

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <h2 className={styles.title}>{data.title}</h2>
        <div className={styles.buttons}>
          {data.Links.map(link => (
            <MyLink {...link} key={link._key} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ContactBottomInfo

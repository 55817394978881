// extracted by mini-css-extract-plugin
export var buttons = "style-module--buttons--vJkCh";
export var container = "style-module--container--vSYp8";
export var isStyleH1 = "style-module--is-style-h1--2WWGA";
export var isStyleH2 = "style-module--is-style-h2--nkyEp";
export var isStyleH3 = "style-module--is-style-h3--lkbGT";
export var isStyleH4 = "style-module--is-style-h4--n2Y4k";
export var isStyleH5 = "style-module--is-style-h5--zVFxn";
export var isStyleH6 = "style-module--is-style-h6--Lc7F8";
export var section = "style-module--section--RCTry";
export var title = "style-module--title--ByPhV";
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/demo/seo"
import ContactHeader from "../components/demo/contactHeader"
import ContactContent from "../components/demo/contactContent"
import ContactBottomInfo from "../components/demo/contactBottomInfo"

const ContactPage = () => {
  return (
    <div>
      <Layout>
        <SEO title="Book a demo page" />
        <ContactHeader />
        <ContactContent />
        <ContactBottomInfo />
      </Layout>
    </div>
  )
}

export default ContactPage

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { ReactComponent as WaveTop } from "./wave-top.svg"
import { ReactComponent as WaveBottom } from "./wave-bottom.svg"
import * as styles from "./style.module.scss"
import BlocksContent from "@components/shared/blocksContent"

const ContactHeader = () => {
  const {
    sanityBookADemo: { header: data },
  } = useStaticQuery(graphql`
    query DemoHeaderQuery {
      sanityBookADemo {
        header {
          title
          description
          backgroundImage {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          richDescription {
            _rawColumnContent
          }
        }
      }
    }
  `)

  return (
    <>
      <GatsbyImage
        image={data.backgroundImage.asset.gatsbyImageData}
        alt={data.backgroundImage.asset.originalFilename}
      />
      <div className={styles.section}>
        <WaveTop />
        <div className={styles.container}>
          <h1 className={styles.title}>{data.title}</h1>
          {data.richDescription && (
            <BlocksContent data={data.richDescription} />
          )}
        </div>
        <WaveBottom />
      </div>
    </>
  )
}

export default ContactHeader

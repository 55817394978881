import React, { createElement } from "react"
import PortableText from "@sanity/block-content-to-react"

import { Maybe, SanityColumn } from "@graphql-types"
import { P } from "@util/standard"
import { Color } from "@util/types"
import Link from "./link"
import { buttonStyle } from "@util/constants"
import styled from "styled-components"
interface Props {
  data: Maybe<SanityColumn> | undefined
  defaultColor?: Color
}

const StyledPortableText = styled(PortableText)`
  z-index: 150;

  ol {
    list-style: none;
  }
  ol li {
    counter-increment: item;
    margin-bottom: 5px;
    &:before {
      margin-right: 10px;
      content: counter(item);
      background: lightblue;
      border-radius: 100%;
      color: white;
      width: 1.2em;
      text-align: center;
      display: inline-block;
    }
  }

  ul {
    list-style: none;
    margin-left: 0px;
  }

  ul li {
    position: relative;
    padding-left: 2rem;
    margin-bottom: 0.8rem;
    &:before {
      width: 18px;
      height: 16px;
      content: url(/assets/bullet.svg);
      font-family: "header bold";
      left: 0;
      top: 3px;
      position: absolute;
    }
  }

  p {
    line-height: 1.5em;
  }
`

const BlocksContent = ({ data }: Props) => {
  if (data == null) {
    return null
  }

  const { _rawColumnContent } = data

  if (_rawColumnContent == null) {
    return null
  }

  const block = (props: any) => {
    const { style = "normal" } = props.node
    const tag = style
    if (/^h\d/.test(tag)) {
      const level = tag.replace(/[^\d]/g, "")
      return createElement(
        tag,
        {
          className: `heading-${level}`,
        },
        props.children
      )
    }
    return <P>{props.children}</P>
  }

  const ColouredSpan = styled.span<{ color: Color }>`
    ${({ color }) => color && `color: ${color};`}

    strong {
      ${({ color }) => color && `color: ${color};`}
    }
  `

  const color = (props: any) => {
    return <ColouredSpan color={props.mark.hex}>{props.children}</ColouredSpan>
  }

  const noBreak = (props: any) => {
    return <nobr>{props.children}</nobr>
  }

  const link = (props: any) => {
    const node = props.node
    return (
      <Link
        linktext={node.linktext}
        kind={node.kind}
        url={node.url}
        internallink={node.internallink}
        theme={buttonStyle[node.buttonDisplay]}
      />
    )
  }

  return (
    <StyledPortableText
      blocks={_rawColumnContent}
      serializers={{
        types: { block, link },
        marks: { color, noBreak },
      }}
      imageOptions={{ auto: "format", fit: "fill" }}
      projectId={process.env.SANITY_PROJECT_ID}
      dataset={process.env.GATSBY_NODE_ENV ?? "development"}
    />
  )
}

export default BlocksContent

// extracted by mini-css-extract-plugin
export var buttons = "style-module--buttons--tCjhb";
export var container = "style-module--container--+7EPC";
export var isStyleH1 = "style-module--is-style-h1--JfF63";
export var isStyleH2 = "style-module--is-style-h2--O2IHo";
export var isStyleH3 = "style-module--is-style-h3--TPGPF";
export var isStyleH4 = "style-module--is-style-h4--S3jby";
export var isStyleH5 = "style-module--is-style-h5--DLcjn";
export var isStyleH6 = "style-module--is-style-h6--+XaGW";
export var section = "style-module--section--52W5v";
export var title = "style-module--title--yZ629";